import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <h3>SiteBunker is a web service provider that receives and stores personal data of customers in
                  accordance with EU regulations.</h3>
                <p>This Privacy Statement is a complete privacy policy applicable to Sitebunker.net’s online activities.
                  This statement details the types of information we collect from customers and how we use this
                  information.</p>
                <p> Our website complies with the General Data Protection Regulation (“GDPR”), (Regulation (EU)
                  2016/679), which is a binding legal act. The data protection policy in SiteBunker is based on the
                  following data protection principles:</p>
                <ul>
                  <li>Personal data processing will be done legally, fairly and transparently.</li>
                  <li>Collection of personal data will only be done for specified purposes and data will not be further
                    processed in an incorrect manner towards those purposes;
                  </li>
                  <li>The collection of personal data will be appropriate, relevant and limited to the information
                    necessary for the purpose of the processing;
                  </li>
                  <li>Personal data will be authentic and, where necessary, updated;</li>
                  <li>All necessary steps shall be taken to ensure that incorrect data is erased or corrected
                    immediately;
                  </li>
                  <li>Personal data will be kept in a form that permits identification of the data subject and for a
                    period no longer than the one in which personal data is processed;
                  </li>
                  <li>All personal data will be kept confidential and stored in a safe manner;</li>
                  <li>Personal data will not be shared with third parties unless necessary for the purpose of providing
                    services under agreements;
                  </li>
                  <li>Individuals concerned have the right to request access to, and rectification and erasure of,
                    personal data, to oppose or restrict data processing as well as data portability.
                  </li>
                </ul>

                <h3>For what purpose do we process your personal data?</h3>
                <p>We collect your information for determined and legitimate purposes including but not limited to:
                </p>
                <ul>
                  <li>In order to conclude or execute a contract between you and us;</li>
                  <li>To answer your questions and requests;</li>
                  <li>For marketing purposes,</li>
                  <li>To provide and improve the services we offer;</li>
                  <li>To diagnose or fix technical issues.</li>
                </ul>

                <h3>Using Cookies</h3>
                <p>The SiteBunker website and its partners use cookies or similar technologies to provide the best user
                  experience and to analyze trends, manage the website, track customer use within the website, and
                  collect demographic information about the user base as a whole. Cookies are small text files that are
                  placed on the client’s device to track usage and record preferences.</p>
                <p>Please also note that this site uses Google Analytics, a web analytics service provided by Google,
                  Inc. ( “Google”).</p>


                <h3>Access and rectification or deletion of personal data</h3>
                <p>Customers have the right to request at any time access to the rectification, erasure or restriction
                  of the processing of data collected by us. To help us keep up-to-date personal data, we recommend
                  users to let us know of any change or discrepancy. To view or modify your personal information or to
                  obtain information about the time period for which Sitebunker intends to retain personal data or other
                  personal data inquiries, or if you would like to find out if, at the request of a third party, we have
                  stored or have processed any of your personal data, please send us an email.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
